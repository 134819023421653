import React, { useContext, useEffect, useRef, useState } from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { emphasize, styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import HomeIcon from '@mui/icons-material/Home';
import { MyContext } from '../../App';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import { ChromePicker } from 'react-color';
import { deleteData, editData, fetchDataFromApi, postData } from '../../utils/api';
import { useNavigate } from 'react-router-dom';
import { FaCloudUploadAlt, FaPencilAlt } from 'react-icons/fa';
import { MdDelete } from 'react-icons/md';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import namer from 'color-namer'; // Importing color-namer

// Breadcrumb styles
const StyledBreadcrumb = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[800],
  height: theme.spacing(3),
  color: theme.palette.text.primary,
  fontWeight: theme.typography.fontWeightRegular,
  '&:hover, &:focus': {
    backgroundColor: emphasize(theme.palette.grey[100], 0.06),
  },
  '&:active': {
    boxShadow: theme.shadows[1],
    backgroundColor: emphasize(theme.palette.grey[100], 0.12),
  },
}));

const Color = () => {
  const [editId, setEditId] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [colorData, setColorData] = useState([]);
  const [formFields, setFormFields] = useState({ color: '', code: '#000000' });
  const [tableLoading, setTableLoading] = useState(false);
  const navigate = useNavigate();
  const context = useContext(MyContext);
  const input = useRef();

  const reloadColors = async () => {
    setTableLoading(true);
    try {
      const data = await fetchDataFromApi('/api/productColor');
      setColorData(data);
    } catch (error) {
      handleApiError(error, 'Failed to load colors.');
    } finally {
      setTableLoading(false);
    }
  };

  useEffect(() => {
    reloadColors();
  }, []);

  const inputChange = (e) => {
    setFormFields((prevFields) => ({
      ...prevFields,
      [e.target.name]: e.target.value,
    }));
  };

  const handleColorChange = (color) => {
    try {
      const names = namer(color.hex); // Get color names
      const closestName = names?.ntc?.[0]?.name || 'Unknown'; // Safely access color name
      setFormFields((prevFields) => ({
        ...prevFields,
        code: color.hex, // Update code with the hex value
        color: closestName, // Update color with the closest name
      }));
    } catch (error) {
      console.error('Error processing color:', error);
      context.setAlertBox({
        open: true,
        error: true,
        msg: 'Failed to process selected color. Please try again.',
      });
    }
  };

  const handleApiError = (error, msg) => {
    console.error(error);
    context.setAlertBox({ open: true, error: true, msg });
  };

  const addColor = async (e) => {
    e.preventDefault();
    if (!formFields.color || !formFields.code) {
      context.setAlertBox({
        open: true,
        error: true,
        msg: 'Please provide both color name and code.',
      });
      return;
    }

    setIsLoading(true);
    const apiCall = editId === '' ? postData : editData;
    const url = editId === '' ? '/api/productColor/create' : `/api/productColor/${editId}`;
    try {
      await apiCall(url, formFields);
      setEditId('');
      setFormFields({ color: '', code: '#000000' });
      await reloadColors();
    } catch (error) {
      handleApiError(error, 'Failed to save color.');
    } finally {
      setIsLoading(false);
    }
  };

  const deleteItem = async (id) => {
    try {
      await deleteData(`/api/productColor/${id}`);
      reloadColors();
    } catch (error) {
      handleApiError(error, 'Failed to delete color.');
    }
  };

  const updateData = async (id) => {
    input.current.focus();
    try {
      const res = await fetchDataFromApi(`/api/productcolor/${id}`);
      setEditId(id);
      setFormFields({ color: res.color, code: res.code });
    } catch (error) {
      handleApiError(error, 'Failed to load color details.');
    }
  };

  return (
    <div className="right-content w-100">
      {/* Header Section */}
      <div className="card shadow border-0 w-100 flex-row p-4 mt-2">
        <h5 className="mb-0">Add Color</h5>
        <Breadcrumbs aria-label="breadcrumb" className="ml-auto breadcrumbs_">
          <StyledBreadcrumb
            component="button"
            onClick={() => navigate('/dashboard')}
            label="Dashboard"
            icon={<HomeIcon fontSize="small" />}
          />
          <StyledBreadcrumb component="button" label="Colors" />
          <StyledBreadcrumb label="Add Color" />
        </Breadcrumbs>
      </div>

      {/* Form Section */}
      <form onSubmit={addColor} className="form">
        <div className="row">
          <div className="col-sm-9">
            <div className="card p-4 mt-0">
              <TextField
                label="Color Name"
                variant="outlined"
                fullWidth
                name="color"
                value={formFields.color}
                onChange={inputChange}
                inputRef={input}
                disabled={isLoading}
              />
              <ChromePicker color={formFields.code} onChangeComplete={handleColorChange} />
              <Button
                type="submit"
                className="btn-blue btn-lg btn-big w-100 mt-3"
                disabled={isLoading}
              >
                <FaCloudUploadAlt /> &nbsp; {isLoading ? <CircularProgress size={20} /> : 'PUBLISH AND VIEW'}
              </Button>
            </div>
          </div>
        </div>
      </form>

      {/* Table Section */}
      {tableLoading ? (
        <CircularProgress className="mt-4" />
      ) : colorData.length > 0 ? (
        <div className="row mt-3">
          <div className="col-md-9">
            <div className="card p-4 mt-0">
              <Table>
                <TableHead>
                  <TableRow>
                   {/* <TableCell>ID</TableCell>*/}
                    <TableCell>COLOR NAME</TableCell>
                    <TableCell>CODE</TableCell>
                    <TableCell>ACTIONS</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {colorData.map((item) => (
                    <TableRow key={item.id}>
                      {/*<TableCell>{item.id}</TableCell>*/}
                      <TableCell>{item.color}</TableCell>
                      <TableCell>{item.code}</TableCell>
                      <TableCell>
                        <div className="actions d-flex align-items-center">
                          <Button color="success" onClick={() => updateData(item.id)}>
                            <FaPencilAlt />
                          </Button>
                          <Button color="error" onClick={() => deleteItem(item.id)}>
                            <MdDelete />
                          </Button>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </div>
        </div>
      ) : (
        <Typography variant="body2" align="center" className="mt-4">
          No colors available. Add a new color to get started.
        </Typography>
      )}
    </div>
  );
};

export default Color;
